import React from 'react'
import classNames from 'classnames'
import { ScrollRestoration } from 'react-router-dom'
import { Col, Container, Row } from '../../elements/grid'
import { Layout } from '../../layout'
import styles from './styles.module.scss'
import { Helmet } from 'react-helmet'
import { Team as HomeTeam } from '../home/team/'
interface teamMemberProps {
  isReverseColClass?: boolean
}
const imgClass = 'pure-img'

export const Nathan = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/nate_headshot.webp" alt="Nathan Mulholland"/>
        <h1 id="nathan-mulholland">Nathan Mulholland</h1>
        <h3>
          Assistant Coach
        </h3>
        <p>
          Nathan brings a broad spectrum of expertise conducting business with regional, national, and international clients across eight countries in the Americas and Europe. Feeling battered by the grind of the business world, Nathan left a storied 20+ year career in international sales, business development and entrepreneurship in 2022 to embark on a personal journey of self-discovery and self-improvement. Along this personal journey, he sought guidance through psychotherapy, nutritional support, and exercise. This whole-person approach opened his eyes to the fundamental building blocks of human health and well being.
        </p>
        <p>
          Inspired by his own journey, Nathan founded am[shift] alongside Mike Shepherd, which is a wellness company that inspires individuals to begin their day by giving back to themselves. This led to a serendipitous partnership with eFIT institute, where Nathan serves both eFIT and am[shift] members as they pursue their emotional fitness, and supports sales of both ventures. Nathan is hard to miss in the space. His loud and proud presence fills the centre as he aims to welcome everyone who walks through our doors with open arms.
        </p>
      </Col>
    </Row>
  )
}

export const Mike = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/mike_headshot.webp" alt="Mike Shepherd"/>
        <h1 id="mike-shepherd">Mike Shepherd</h1>
        <h3>
          Assistant Coach
        </h3>
        <p>
          Mike completed his certification as a Personal Trainer through the International Sports Sciences Association (ISSA), and works as an Assistant Coach at eFit. His extensive experience includes serving as a team leader and working as a certified trainer, which has honed his ability to connect with people and inspire them through his own actions. Mike's mission is to guide his clients as they learn to embrace and celebrate their bodies, foster self-love, and develop a lifelong commitment to their health and well being. Mike aims to instill confidence in those he works with, enabling them to perform at any public gym with ease and without self-consciousness.
        </p>
        <p>
          Prior to finding his own wellness journey through physical movement, Mike completed an Integrated Accounting program at Mohawk College and also worked as a chef. His passion for both food and numbers, gives him an eye for good physical programming and a stomach for delicious cuisine to fuel his workouts. If you feel like geeking out about great food options to support your training, Mike's your guy.
        </p>
        <p>
          At eFit, Mike is dedicated to helping members achieve their wellness goals and transform their lives. As part of the eFIT team, Mike is working with our psychologists and dietitian to embrace both a trauma and eating disorder-informed approach to physical wellness, and aims to deliver an experience that makes physical wellness feel accessible to anyone.
        </p>
      </Col>
    </Row>
  )
}

export const Courtney = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/courtney_headshot.webp" alt="Courtney Howlett"/>
        <h1 id="courtney-howlett">Courtney Howlett M.OMSc.</h1>
        <h3>
          Trauma Informed Osteopath
        </h3>
        <p>
          Courtney is a trauma informed Osteopath with a passion for empowering others to tap into their own natural healing abilities. In addition to Osteopathy, Courtney brings years of study and holistic training in yoga, therapeutics, somatics, breathwork, meditation, mindfulness and ayurveda.
        </p>
        <p>
          With this collective knowledge, Courtney also offers intentional classes and integrative workshops for well-being that focus on functional understanding, practical tools for improved health and awareness of the mind-body-spirit connection.
        </p>
      </Col>
    </Row>
  )
}


export const Daniel = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/daniel_headshot.webp" alt="Daniel Frankel, M.Sc., R.Kin."/>
        <h1 id="daniel-frankel">Daniel Frankel, MScPT</h1>
        <h3>
        Physiotherapist and Head Coach
        </h3>
        <p>
          Daniel Frankel is a registered physiotherapist and strength & conditioning coach with over a decade of experience in the health and fitness industry. He holds a Master of Science in Physiotherapy and is dedicated to helping clients achieve their health and performance goals.
        </p>
        <p>
          Daniel specializes in working with clients to improve their strength, aerobic capacity, coordination, and mobility. He has a keen interest in exercise rehabilitation and works with individuals recovering from injuries as well as those looking to enhance their athletic performance.
        </p>
        <p>
          In addition to his work with clients, Daniel is passionate about educating other health and fitness professionals. He regularly conducts workshops and seminars to help coaches develop their communication and observation skills, ensuring they can provide the best possible support to their clients.
        </p>
        <p>
          Daniel believes in a holistic approach to health and fitness, focusing on both physical and mental well-being. He works closely with clients to create personalized programs that address their unique needs and goals, helping them build lifelong habits for a healthier, more resilient future.
        </p>
      </Col>
    </Row>
  )
}

export const Katherine = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={styles.bio}>
        <img width="100%" height="100%"  className={imgClass} src="/static/team/katherine_headshot.webp" alt="Dr. Katherine Gardhouse, Ph.D., C.Psych."/>
        <h1 id="katherine-gardhouse">DR. Katherine Gardhouse, PH.D., C.PSYCH.</h1>
        <h3>
          Director & Founder, Clinical & Health Psychologist, Assistant Professor McMaster University
        </h3>
        <p>
          Dr. Katherine Gardhouse founded eFIT Institute and the Centre for Functional Integrative Therapy (CFIT) in 2022 as part of a larger mission to make high quality mental health care more accessible, and to support the development of an institute dedicated to the advancement of high-quality evidence-based psychological interventions that incorporate biopsychosocial aspects of care within one system. Katherine views mental health as a complex interplay of biological, psychological, and social factors. Throughout her research and clinical practice, Katherine aims to develop intervention approaches that recognize this complexity and offer a comprehensive framework for understanding and addressing mental health and well-being.
        </p>
        <p>
          Katherine’s vision for eFIT began a decade earlier, when she felt a deep sense of dissatisfaction with current approaches to mental health care that were within the medical model and singular in their focus. While Katherine has a deep appreciation for the medical model in advancing our understanding and treatment of mental health conditions, she also recognized the many ways in which it falls short in addressing the complexity and reality of human experiences. Instead, Katherine had a vision of moving mental health care out of the confines of hospital settings and medical offices, and back into the community where an emphasis can be placed on the social, emotional, physical, and environmental factors that significantly impact mental well-being. In creating eFIT Institute and the Centre for Functional Integrative Therapy, Katherine aims to deploy an advanced biopsychosocial model of mental health care that considers the full spectrum of influences on an individual's well-being, directly in the community of Hamilton and beyond.
        </p>
        <p>
          Katherine is a licensed clinical, counselling, and health psychologist. In addition to her role at eFIT, she holds a position as a staff psychologist at St. Joseph’s Healthcare Hamilton in the Eating Disorder Program and is an Assistant Professor at McMaster University in the Department of Psychiatry and Behavioural Neurosciences. She completed her Ph.D. in the Psychological Clinical Science Department at the University of Toronto and her pre and postdoctoral residency at the Centre for Addiction and Mental Health (CAMH) in the Inpatient Trauma Unit and Borderline Personality Disorder (BPD) Clinic. During her fellowship, she conducted research and clinical intervention on a randomized controlled trial looking at outcomes of trauma-adaptions of Dialectical Behaviour Therapy (DBT) for individuals with BPD who have experienced trauma and chronic invalidation throughout their lives.
        </p>
        <p>
          Katherine has worked in a variety of settings, including inpatient and outpatient hospital programs, community mental health, private practices, and clinical research trials. Katherine provides evidence-based treatment for adults experiencing problems related to eating, body image, posttraumatic stress disorder (PTSD), trauma, substances, emotion regulation, and relationship difficulties. Her therapeutic approach is integrative, drawing primarily from Emotion Focused Therapy (EFT), Dialectical Behaviour Therapy (DBT), Cognitive Behavioural Therapy (CBT), somatic, mindfulness, and compassion-focused techniques.
        </p>
        <p>
          Katherine believes in a comprehensive approach that considers the full person, fostering healing through empathy, connection, and understanding. Incorporating a biopsychosocial approach ensures above all else that care is patient-centered, addressing the unique needs and experiences of each person. Katherine works collaboratively with clients in a secure, non-judgmental, and culturally sensitive environment to help them access and build on their strengths and achieve their goals.
        </p>
        <p>
          Katherine believes that the therapeutic relationship can support clients to rediscover themselves, gain deeper self-understanding, improve health, promote personal growth, and that through the therapeutic process, pain can transform into resiliency. Katherine also works from the perspective that well-being exists at the intersection of physical and psychological health, and thus aims to empower clients to prioritize their emotional and physical needs simultaneously.
        </p>
        <p>
          Katherine is passionate about providing a space where members and staff can come together to develop a life-long relationship with themselves that increases one’s capacity to endure in the face of hardship, to overcome the pains of the past, and support one another to create a life we feel good about.
        </p>
        <p className={styles.hiddenLink}>
          <a title="Dr. Katherine Gardhouse - Psychology Today" href="https://www.psychologytoday.com/ca/therapists/katherine-gardhouse-centre-for-functional-integrative-therapy-hamilton-on/940269">
            Katherine Gardhouse&apos;s Psychology Today page
          </a>
          <a title="Dr. Katherine Gardhouse - LinkedIN" href="https://www.linkedin.com/in/katherine-gardhouse">
            Katherine Gardhouse&apos;s LinkedIn page
          </a>
          <a title="Dr. Katherine Gardhouse - McMaster University" href="https://experts.mcmaster.ca/display/gardhouk">
          Katherine Gardhouse
Assistant Professor (Part-Time), Psychiatry & Behavioural Neurosciences
          </a>
          <a title="Dr. Katherine Gardhouse - Clinical Neurosciences Labratory" href="https://clinicalneurosciences.ca/katherine-gardhouse">

Katherine Gardhouse, Ph.D., C.Psych - Clinical Neurosciences Labratory - University of Toronto
          </a>
          <a title="Dr. Katherine Gardhouse - Centre for Psychology and Mental Health" href="https://www.cpeh.ca/katherine-gardhouse">
            Dr. Katherine Gardhouse - Centre for Psychology and Mental Health
          </a>
        </p>
      </Col>
    </Row>
  )
}
export const Tara = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/tara_headshot.webp" alt="Tara Gralnick, Ph.D. (Supervised Practice)"/>
        <h1 id="tara-gralnick">Tara Gralnick, Ph.D. (Supervised Practice)</h1>
        <h3>Cofounder</h3>
        <p>Tara Gralnick has a Ph.D. in Clinical Psychology from the University of Toronto Scarborough. She views the therapeutic relationship as integral to successful psychotherapy, adopting a genuine, empathetic, and non-judgmental style with her clients. She strives to deeply attune to her clients’ experiences, providing a sense of safety that facilitates emotional exploration. She is grateful to have received in-depth training in Emotion Focused Therapy (EFT), and primarily draws from this modality to help her clients access the root of their suffering and create deep, meaningful changes in their lives. She further integrates Cognitive Behavioural Therapy (CBT) and Dialectical Behaviour Therapy (DBT) when needed to help her clients reduce situational distress and ultimately progress towards the deeper work of shifting painful emotions related to past wounds, which so often drive current suffering. Tara finds great meaning in the work that she does. </p>
        <p>Tara works with clients experiencing a range of difficulties, including anxiety (e.g., social anxiety, generalized anxiety, panic), depression, post-traumatic stress disorder (PTSD), psychosis, complex trauma, low self-worth, relationship difficulties, perfectionism, obsessive-compulsive disorder (OCD), overwhelming emotions, life stressors, and self-stigma. It has been her experience that working towards transforming emotional schemes related to past traumatic experiences ultimately liberates her clients from the need to engage in constant symptom management. She has developed a particular interest in working with clients who endured interpersonal traumas and who experience difficulties rooted in shame and self-criticism. She strives to promote cultural safety and apply an intersectional lens when working with clients with diverse racial, ethnic, religious, gender, and sexual identities.</p>
        <p>Tara's training has offered her a lens to understand the human experience at both emotional and intellectual levels, helping her feel competent in embracing the nuance and complexity of each individual’s unique experience. In addition to her role at eFIT, she is currently working at the Centre for Psychology and Emotional Health. She completed her pre-doctoral residency at the Calgary Clinical Psychology Residency Program, with rotations in a hospital-based mood disorders program, early/adult psychosis program, and community mental health clinic. She then completed a postdoctoral fellowship at St. Joseph's Healthcare Hamilton’s Early Intervention Clinic, where she ran a randomized control trial evaluating an intervention she co-developed (a group Cognitive Behavioural Therapy intervention for individuals experiencing combined social anxiety and psychosis). Tara has trained in a variety of settings throughout her graduate studies, having completed rotations at the Centre for Addiction and Mental Health (CAMH)’s Complex Care and Recovery Program, CAMH’s Work, Stress, and Health Program, West End Psychological Services, and DBT Hamilton. In line with her client-centered approach to therapy, which emphasizes clients’ needs and personal goals, her dissertation focused on developing an intervention that aims to help individuals better understand their universal personality traits and to cultivate desired change.</p>
        <p>Tara is passionate about the innovative work being conducted at eFIT and is delighted to be a part of this community.</p>
      </Col>
    </Row>
  )
}

const Laura = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/laura_headshot.webp" alt="Laura Sorbara"/>
        <h1 id="laura-sorbara">Laura Sorbara</h1>
        <h3>Volunteer Coordinator</h3>
        <p>Laura is a lifetime Hamilton resident with an undergraduate degree in fine arts from McMaster University and a master’s degree in information from the University of Toronto. Laura has a passion for art and has shown her work both collaboratively and in solo shows in Hamilton. She loves volunteering with the community fridge program and Pride Hamilton and has joined the eFIT team to facilitate new community and volunteer initiatives within our centre and membership. Laura values strengthening the community and giving back to the fantastic city that has given her so much and is excited about growing a vibrant community within eFIT that will connect the centre to the wider Hamilton area. She also enjoys knitting, printmaking and painting, coffee, plants, and cats. As an eFIT member, you will no doubt hear Laura’s witty sarcasm fill the space and can get in touch with her at any time if you are interested in starting or joining any initiatives within our community. </p>
      </Col>
    </Row>
  )
}
export const Erin = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/erin_headshot.webp" alt="Erin O\'Flaherty RD, Registered Dietitian"/>
        <h1 id="erin-oflaherty">Erin O&apos;Flaherty</h1>
        <h3>RD, Registered Dietitian, Certified Intuitive Eating Counsellor</h3>
        <p>Erin is a registered dietitian with the College of Dietitians of Ontario and a Certified Intuitive Eating Counsellor. Erin earned a bachelor’s degree in Nutrition and Dietetics from Acadia University and completed an 11-month dietetic internship with the Nova Scotia Health Authority. Erin is a staff dietitian in the Eating Disorder Program at St. Joseph’s Healthcare Hamilton and has also worked with the Hamilton Family Health Team and in private practice settings, seeing a wide range of clients and presentations through both individual nutrition counselling and group psychoeducation.</p>
        <p>Erin has expertise working with disordered eating, gut health, and management of chronic disease. She has worked extensively with eating disorder presentations such as anorexia, bulimia, binge eating disorder, OSFED, and ARFID. Erin has a passion for helping individuals transform their difficult relationship with food into a peaceful and enjoyable experience. Erin is weight inclusive and uses an all-foods-fit approach to nutrition to help individuals honour their hunger and break free from the toxic cycle of dieting.</p>
      </Col>
    </Row>
  )
}
export const Jess = (props: teamMemberProps): JSX.Element => {
  const reverseColClass = classNames({
    [styles.bio]: true,
    [styles.reverse]: props.isReverseColClass
  })
  return (
    <Row>
      <Col size={12} tabletSize={12} desktopSize={12} className={reverseColClass}>
        <img loading="lazy" width="100%" height="100%"  className={imgClass} src="/static/team/jess_headshot.webp" alt="Jessica Vlasak"/>
        <h1 id="jessica-vlasak">Jessica Vlasak, RN</h1>
        <h3>Operational Manager and Intake Coordinator</h3>
        <p>Jessica became a registered nurse after completing a Bachelor of Science in Nursing at McMaster University in 2009. She started her nursing career in Pediatric oncology. Shortly after, she met the love of her life and moved to Germany. After a year, she and her now husband moved to Hamilton to lay down roots. They have since resided in Hamilton, getting acquainted with and exploring its colour and diversity.</p>
        <p>Jessica has always viewed nursing as a vital and profound profession centered in therapeutic connection. She is grateful for the opportunity to support and care for her community through her role as a nurse. She discovered her interest and aptness for mental health care early on and made it her focus. </p>
        <p>Jessica has worked on pediatric inpatient units for Mental Health and Eating Disorders at McMaster University Hospital, expanding her understanding and knowledge of diagnoses as well as therapies. She also developed strong connections with patients working in primary care at a family physicians office, specializing in women's health. She has also worked as an Educator for Personal Support Worker and Registered Practical Nursing programs at Anishinebek Educational Institute. </p>
        <p>Jessica became a mother in 2017 and it changed her forever. She recently finished her third and final maternity leave and began important work at eFIT as the Operational Manager and Intake Coordinator, and she couldn’t be more thrilled to be part of the team!</p>
      </Col>
    </Row>
  )
}
export const TeamMember = (): JSX.Element => {
  const url = window.location.href.toLocaleLowerCase()
  let extraTitle = ''
  let component
  let scrollToAnchor = 'katherine-gardhouse'
  if (url.includes('gardhouse')) {
    extraTitle = 'Dr. Katherine Gardhouse - '
    component = <Katherine />
  } else if (url.includes('gralnick')) {
    extraTitle = 'Tara Gralnick, Ph.D. - '
    scrollToAnchor = 'tara-gralnick'
    component = <Tara isReverseColClass={false} />
  } else if (url.includes('oflaherty')) {
    extraTitle = 'Erin OFlaherty - '
    scrollToAnchor = 'erin-oflaherty'
    component = (
      <div>
        <Erin />
        <Helmet>
            <title>Erin O&apos;Flaherty - eFIT</title>
        </Helmet>
      </div>
     )
  } else if (url.includes('sorbara')) {
    extraTitle = 'Laura Sorbara - '
    scrollToAnchor = 'laura-sorbara'
    component = <Laura isReverseColClass={false} />
  } else if (url.includes('jessica-vlasak')) {
    extraTitle = 'Jessica Vlasak - '
    scrollToAnchor = 'jessica-vlasak'
    component = <Jess />
  } else if (url.includes('daniel-frankel')) {
    extraTitle = 'Daniel Frankel'
    scrollToAnchor = 'daniel-frankel'
    component = <Daniel />
  } else if (url.includes('courtney-howlett')) {
    extraTitle = 'Courtney Howlett'
    scrollToAnchor = 'courtney-howlett'
    component = <Courtney />
  }
  return (
    <Layout>
      <Helmet>
        <meta name="description" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`} />
        <title>{`${extraTitle}eFIT`} - Mental Health - Hamilton - Dr. Katherine Gardhouse</title>
        <meta property="og:title" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`} />
        <meta property="og:description" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`} />
        <meta property="og:image" content="/static/efit-logo.webp" />
        <meta property="og:url" content={`https://efit.institute/team/${scrollToAnchor}`} />
        <meta itemProp="name" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`} />
        <meta itemProp="url" content={`https://efit.institute/team/${scrollToAnchor}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`} />
        <meta name="twitter:description" content={`${extraTitle}eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse`}/>
        <meta name="twitter:image" content="/static/efit-logo.webp" />
      </Helmet>
      <Container>
        { component }
      </Container>
      <HomeTeam />
      <ScrollRestoration />
    </Layout>
  )
}
export const Team = (): JSX.Element => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <title>Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse</title>
        <meta property="og:title" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta property="og:description" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta property="og:image" content="/static/efit-logo.webp" />
        <meta property="og:url" content={`https://efit.institute/team`} /> 
        <meta itemProp="name" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta itemProp="url" content={`https://efit.institute/team`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta name="twitter:description" content="Team - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse"/>
        <meta name="twitter:image" content="/static/efit-logo.webp" />
      </Helmet>
      <Container>
        <Row>
          <Col size={12} tabletSize={12} desktopSize={12}>
            <h1 id="meet-the-team">Meet the team</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Katherine />
        <Tara isReverseColClass={true} />
        <Erin isReverseColClass={false} />
        <Jess isReverseColClass={true} />
        <Courtney isReverseColClass={false} />
        <Daniel isReverseColClass={true} />
        <Mike isReverseColClass={false} />
        <Nathan isReverseColClass={true} />
        <Laura isReverseColClass={false} />
      </Container>
      <ScrollRestoration />
    </Layout>
  )
}
// <Col size={12} tabletSize={4} desktopSize={3}>Hello</Col>
//           <Col size={12} tabletSize={4} desktopSize={3}>Hello</Col>
//           <Col size={12} tabletSize={4} desktopSize={3}>Hello</Col>
//           <Col size={12} tabletSize={4} desktopSize={3}>Hello</Col>

